<template>
  <div />
</template>

<script>

import {prepareSinRequest, prepareSinResponse} from "@/utils/http";
import BaseAction from "@/components/actions/BaseAction";

export default {
  name: 'OpenCertificate',
  props: {
    collection: {
      type: Object,
      required: true,
    },
  },
  mixins: [
    BaseAction,
  ],
  mounted(){
    if (!this.selected) {
      return;
    }

    this.getCertificate();
  },
  methods:{
    async getCertificate() {
      const routesregisterId = this.selected.routesregisterId;
      const dateBegin = this.$store.getters['period/begin'];
      const dateEnd = this.$store.getters['period/end'];
      const startDtMillis = dateBegin.getTime();
      const endDtMillis = dateEnd.getTime();
      const query = `sin2:/v:0e6241ee-2dd5-4860-89ba-0324c0f0e7fb/?filter=and(eq(field(".servicedRouteID"),param("${routesregisterId}", "id")),not(eq(field(".stateID"),param("c2cd5446-7849-4ab4-89f8-bcef841fd2a0", "id"))),gte(field(".endDt"),var("util.date.dateFromMillis(${startDtMillis}l)","date")),lte(field(".startDt"),var("util.date.dateFromMillis(${endDtMillis}l)","date")))&fields=.id,.tmpFile,.isfile,.cardSeries,.cardNumber`
      const rs = await prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          query
        ),
        {
          hideUnderscores: true,
        },
      );
      let msg = 'Документ не добавлен в систему для свидетельства';
      let delimiter = "";
      if (rs.length > 0) {
        for (const row of rs) {
          if (row.isfile) {
            location.href = '/rpc/?d=file&uri=fs:id:' + row.tmpfile;
          } else {
            msg += delimiter + " серия " + row.cardseries + " номер " + row.cardnumber;
            delimiter = ",";
          }
        }
        if (msg !== 'Документ не добавлен в систему для свидетельства') {
          msg += ".\nДля его добавления перейдите в раздел «3 Организация пассажирских перевозок - 3.3 Реестр свидетельств маршрутов».";
          this.showMessage('warning', msg)
        }
      } else {
        this.showMessage('warning', 'На рабочий период нет действующего свидетельства.\nДля его добавления перейдите в раздел «3 Организация пассажирских перевозок - 3.3 Реестр свидетельств маршрутов».')
      }
    },
  },
}
</script>
